













import { mapGetters, mapState } from 'vuex';
export default {
  name: 'OnboardingOperatorTypeAlert',
  computed: {
    ...mapState('Onboarding', ['selectedOperator']),
    ...mapGetters('Onboarding', ['isTestEnv']),
    text(): string {
      return this.isTestEnv ? 'Test account' : 'Product account';
    },
    color(): string {
      return this.isTestEnv ? 'orange darken-2' : 'success';
    }
  }
};
