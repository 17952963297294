import { http } from '@/api/Connect';
import { BASE_OPERATOR } from '@/api/CopyStake/BaseUrls';
import { AxiosResponse } from 'axios';

import {
  ICopyStakeUsersBlacklistParam,
  ICopyStakeUsersBlacklistResponse
} from '@/api/schema';

const API_BLACKLIST = (): string => `${BASE_OPERATOR()}/blacklist`;

export async function getCopyStakeUsersBlacklist(
  params: ICopyStakeUsersBlacklistParam
): Promise<ICopyStakeUsersBlacklistResponse> {
  return http
    .get(API_BLACKLIST(), {
      params,
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse): ICopyStakeUsersBlacklistResponse => data);
}

export async function addCopyStakeUserBlacklist(
  data: string[]
): Promise<string[]> {
  return http
    .post(API_BLACKLIST(), data)
    .then(({ data }: AxiosResponse): string[] => data);
}

export async function removeCopyStakeUserBlacklist(
  data: string[]
): Promise<string[]> {
  return http
    .delete(API_BLACKLIST(), { data })
    .then(({ data }: AxiosResponse): string[] => data);
}

export async function removeAllCopyStakeUserBlacklist(): Promise<string[]> {
  return http
    .delete(`${API_BLACKLIST()}/users`)
    .then(({ data }: AxiosResponse): string[] => data);
}
