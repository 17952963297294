











































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import OnboardingProviderDialog from './OnboardingProviderDialog.vue';
import OnboardingOperatorTypeAlert from './OnboardingOperatorTypeAlert.vue';
import OnboardingWelcomeDescription from '@/views/OnboardingView/OnboardingWelcomeDescription.vue';
import OnboardingTokenInfo from '@/views/OnboardingView/onboardingStepsComponents/OnboardingTokenInfo.vue';
import OnboardingUISettings from '@/views/OnboardingView/onboardingStepsComponents/OnboardingUISettings.vue';
import OnboardingWhitelistIP from '@/views/OnboardingView/onboardingStepsComponents/OnboardingWhitelistIP.vue';
import OnboardingAccountCreation from '@/views/OnboardingView/onboardingStepsComponents/OnboardingAccountCreation.vue';
import OnboardingGetURL from '@/views/OnboardingView/onboardingStepsComponents/OnboardingGetURL.vue';
import OnboardingTokenExchange from '@/views/OnboardingView/onboardingStepsComponents/OnboardingTokenExchange.vue';
import OnboardingTrafficProgress from '@/views/OnboardingView/onboardingStepsComponents/OnboardingTrafficProgress.vue';
import OnboardingIntegrationSettings from '@/views/OnboardingView/onboardingStepsComponents/OnboardingIntegrationSettings.vue';
import OnboardingUserSupport from '@/views/OnboardingView/onboardingStepsComponents/OnboardingUserSupport.vue';
import OnboardingInviteDeveloper from '@/views/OnboardingView/onboardingStepsComponents/OnboardingInviteDeveloper.vue';
import {
  addGameProvider,
  createOperator,
  deleteGameProviderIp,
  updateOperator
} from '@/api/Onboarding';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';
import type {
  GameProviderData,
  OperatorOnboardingForm,
  OperatorOnboardingResponseData,
  TOnboardingPayloadStepType
} from '@/api/schema';
import { EOnbordingStep } from '@/api/schema';
import goTo from 'vuetify/lib/services/goto';
import { isSuperAdmin } from '@/helpers/superAdminHelper';

const OnboardingGameProviders = (): any =>
  import(
    '@/views/OnboardingView/onboardingStepsComponents/OnboardingGameProviders.vue'
  );

export default {
  name: 'OnboardingView',
  components: {
    OnboardingProviderDialog,
    OnboardingOperatorTypeAlert,
    OnboardingWelcomeDescription,
    OnboardingTokenInfo,
    OnboardingUISettings,
    OnboardingWhitelistIP,
    OnboardingAccountCreation,
    OnboardingGetURL,
    OnboardingTokenExchange,
    OnboardingTrafficProgress,
    OnboardingIntegrationSettings,
    OnboardingUserSupport,
    OnboardingInviteDeveloper,
    OnboardingGameProviders
  },
  data: (): any => ({
    sendingFirstForm: false,
    sendingDialogForm: false,
    widgetSettingsState: null,
    tokenInfoState: null,
    expandSteps: {
      [EOnbordingStep.tokenInfoProgress]: false,
      [EOnbordingStep.uiSettingsProgress]: false,
      [EOnbordingStep.whitelistProgress]: false,
      [EOnbordingStep.accountCreationProgress]: false,
      [EOnbordingStep.getUrlProgress]: false,
      [EOnbordingStep.tokenExchangeProgress]: false,
      [EOnbordingStep.trafficProgress]: false,
      [EOnbordingStep.integrationSettingsProgress]: false,
      [EOnbordingStep.userSupportProgress]: false,
      [EOnbordingStep.inviteDevProgress]: false,
      [EOnbordingStep.gameProviders]: false
    }
  }),
  computed: {
    ...mapState('Auth', ['token']),
    ...mapState('Onboarding', [
      'selectedOperator',
      'onboardingData',
      'onboardingForm',
      'onboardingProgress',
      'accountInfo'
    ]),
    ...mapGetters('Onboarding', [
      'isTestEnv',
      'createOperatorData',
      'operatorId',
      'operatorApproved',
      'isMobile',
      'isProxy',
      'operatorProgress'
    ]),
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('app', ['isCopyStakeRole']),
    ...mapGetters('SuperAdmin', ['isCurrentCopyStakeAccount']),
    isCopyStakeAccount(): boolean {
      return this.isCopyStakeRole || this.isCurrentCopyStakeAccount;
    },
    envName(): string {
      return this.selectedOperator?.envName;
    },
    formDisabled(): boolean {
      return this.selectedOperator.deployed;
    },
    firstBlockSteps(): any {
      return [
        {
          component: 'OnboardingTokenInfo',
          visible: this.$role.can.read('home token')
        },
        {
          component: 'OnboardingUISettings',
          visible: this.$role.can.read('home colors')
        }
      ].filter(({ visible }) => visible || this.isSuperAdmin);
    },
    stepsGroupsTestOperator(): any {
      const steps = [
        {
          component: 'OnboardingInviteDeveloper',
          visible: this.$role.is(['admin', 'account'])
        },
        {
          component: 'OnboardingWhitelistIP',
          visible: this.$role.can.read('home integration')
        },
        {
          component: 'OnboardingAccountCreation',
          visible: this.$role.is([
            'admin',
            'developer',
            'account',
            'copystake-account',
            'copystake-admin'
          ])
        },
        {
          component: 'OnboardingGetURL',
          visible: this.$role.is(['admin', 'developer', 'account'])
        },
        {
          component: 'OnboardingTokenExchange',
          visible: this.$role.can.read('home token')
        },
        {
          component: 'OnboardingTrafficProgress',
          visible: this.$role.can.update('home integration')
        }
      ].filter(({ visible }) => visible || this.isSuperAdmin);

      const stepsCount = steps.length;
      const stepsTxt = `${stepsCount} step${stepsCount > 1 ? 's' : ''}`;

      return Object.freeze([
        {
          steps: this.firstBlockSteps
        },
        {
          title: 'Trueplay integration',
          description: `Just ${stepsTxt} to finish your integration`,
          steps
        }
      ]);
    },
    stepsGroupsProdOperator(): any {
      const steps = [
        {
          component: 'OnboardingIntegrationSettings',
          visible: this.$role.can.update('home integration')
        },
        {
          component: 'OnboardingUserSupport',
          visible: this.$role.is([
            'admin',
            'account',
            'copystake-account',
            'copystake-admin'
          ])
        }
      ].filter(({ visible }) => visible || this.isSuperAdmin);

      return Object.freeze([
        {
          steps: [...this.firstBlockSteps, ...steps]
        }
      ]);
    },
    currentStepsGroups(): any {
      return this.isTestEnv
        ? this.stepsGroupsTestOperator
        : this.stepsGroupsProdOperator;
    }
  },
  watch: {
    isTestEnv: {
      handler(): void {
        Object.entries(this.expandSteps).forEach(([key]) => {
          this.expandSteps[key] = false;
        });
      },
      immediate: true
    },
    operatorId: {
      handler(newId: number | null, oldId: number | null): void {
        if (newId) {
          this.getProviders();
          this.getTruePlayIPs();
          if (
            (oldId || isSuperAdmin) &&
            this.$role.is([
              'account',
              'admin',
              'super-admin',
              'marketer',
              'developer'
            ])
          ) {
            this.getOnboardingProgress(newId);
          }
        }
      },
      immediate: true
    },
    'onboardingData.name'(): void {
      this.panel = false;
      this.widgetSettingsState = null;
      this.tokenInfoState = null;
    },
    'onboardingData.id': {
      handler(newId: number | undefined): void {
        if (!newId) {
          this.panel = false;

          return;
        }
        this.getApiKeys();
        this.getOperatorMode();
        this.getOperatorIPs();
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('Auth', ['setTokenSymbol']),
    ...mapMutations('Onboarding', ['setNewOperator', 'setSelectedOperator']),
    ...mapActions('Onboarding', [
      'getProviders',
      'getApiKeys',
      'hideProviderDialog',
      'getTruePlayIPs',
      'getOperatorIPs',
      'getOperatorMode',
      'getAllOperators',
      'getOnboardingProgress',
      'updateOnboardingProgress'
    ]),
    updateOnboardingProgressHandler(step: TOnboardingPayloadStepType): void {
      this.updateOnboardingProgress(step);
    },
    switchExpandedSteps(
      stepType: TOnboardingPayloadStepType,
      isExpandValue: boolean
    ): void {
      if (isExpandValue) {
        Object.keys(this.expandSteps).forEach((key) => {
          this.expandSteps[key] = key === stepType;
        });
      } else {
        this.expandSteps[stepType] = false;
      }
    },
    firstStepSubmitHandler(formData: OperatorOnboardingForm): void {
      this.sendingFirstForm = true;
      const handler = this.selectedOperator.id
        ? updateOperator
        : createOperator;

      handler(formData)
        .then((res: OperatorOnboardingResponseData): void => {
          this.setTokenSymbol(this.createOperatorData.tokenName);
          this.getAllOperators();
          this.setNewOperator({});
          this.setSelectedOperator(res);
          goTo(0);
          this.$toast.success(
            'Thanks, the info has been sent to the Trueplay team',
            {
              color: 'success',
              icon: 'mdi-check-circle',
              iconColor: '#ffffff'
            }
          );

          if (this.isMobile) {
            this.$router.push('/play-to-earn-events');
          }
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err, {
            iconColor: '#ffffff',
            color: 'error',
            icon: 'mdi-alert-circle'
          });
        })
        .finally((): void => {
          this.sendingFirstForm = false;
        });
    },
    addProviderHandler(data: GameProviderData): void {
      this.sendingDialogForm = true;
      addGameProvider(data)
        .then(() => {
          this.hideProviderDialog();
          this.$toast.success('Provider added.');
          this.getProviders();
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.sendingDialogForm = false;
        });
    },
    handlerDeleteGameProvider(data: { name: string; ip: string }): void {
      deleteGameProviderIp(data)
        .then(() => {
          this.$toast.success('Ip address has remove');
          this.getProviders();
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    }
  }
};
