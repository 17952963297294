





















































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { getFormInfo, updateOperator } from '@/api/Onboarding';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import CodeBlock from '@/components/code-blocks/CodeBlock.vue';
import CodeBlockWrapper from '@/components/code-blocks/CodeBlockWrapper.vue';
import * as vr from '@/helpers/validation';

export default {
  name: 'OnboardingTokenExchangeContent',
  components: {
    CodeBlock,
    CodeBlockWrapper
  },
  props: {
    completed: {
      type: Boolean,
      default: false
    }
  },
  data: (): unknown => ({
    formValid: true,
    baseUrl: '',
    urlHint: false,
    prodUrl: '',
    prodFormData: {},
    urlRules: [(v) => vr.required(v, 'Callback URL'), vr.urlWithoutProtocol]
  }),
  computed: {
    ...mapState('Onboarding', ['truePlayIPs', 'secret', 'operators']),
    ...mapGetters('Onboarding', ['onboardingForm', 'isTestEnv']),
    url: {
      get(): string {
        const url = this.onboardingForm.url || this.baseUrl;
        return url.replace('https://', '');
      },
      set(v: string): void {
        this.baseUrl = !v ? '' : ('https://' + v.trim()).replaceAll(' ', '');
      }
    },
    disableSaveBtn(): boolean {
      return (
        !this.baseUrl?.length ||
        !this.formValid ||
        this.baseUrl === this.onboardingForm.url
      );
    },
    prodOperatorId(): string {
      return this.operators.find(({ test }) => !test)?.id;
    }
  },
  mounted(): void {
    if (this.isTestEnv) {
      getFormInfo(this.prodOperatorId).then((data) => {
        this.prodFormData = data;
        this.prodUrl = data.url;
      });
    }
  },
  methods: {
    ...mapActions('Onboarding', ['getProviders']),
    ...mapMutations('Onboarding', ['setOnboardingFormData']),
    updateOperatorInfo(): void {
      const form = this.$refs.form;
      form.validate();

      this.$nextTick((): void => {
        if (this.formValid) {
          const onboardingForm = {
            ...this.onboardingForm,
            ...{ url: this.baseUrl }
          };
          updateOperator(onboardingForm)
            .then(() => {
              if (!this.prodUrl && this.isTestEnv) {
                updateOperator(
                  { ...this.prodFormData, url: this.baseUrl },
                  this.prodOperatorId
                );
              }
              this.$toast.success('Operator data updated!');
              this.setOnboardingFormData({ url: this.baseUrl });
              this.urlHint = true;
              setTimeout(() => (this.urlHint = false), 1000);
              window.dataLayer.push({
                event: 'operator_base_url_click',
                eventCategory: 'onboarding',
                eventAction: 'click',
                eventLabel: 'operator_base_ur'
              });
            })
            .catch(errorToastMessage);
        }
      });
    },
    copySecretKeyAction(): void {
      window.dataLayer.push({
        event: 'secret_key_copy_click',
        eventCategory: 'onboarding',
        eventAction: 'click',
        eventLabel: 'secret_key_copy_click'
      });
    },
    copyIPActionvoid(): void {
      window.dataLayer.push({
        event: 'trueplay_ip_copy_click',
        eventCategory: 'onboarding',
        eventAction: 'click',
        eventLabel: 'trueplay_ip_copy_click'
      });
    }
  }
};
