

































































import { mapGetters, mapState, mapActions } from 'vuex';
import { TokensInfoState } from '@/store/modules/tokens-info/TokensInfoState';
import { updateOperator } from '@/api/Onboarding';
import OnboardingValidationMixin from '@/mixins/Onboarding/OnboardingValidationMixin';

export default {
  name: 'OnboardingTokenInfoContent',
  mixins: [OnboardingValidationMixin],
  data: (): any => ({
    editTiles: {
      tokenTicker: false,
      tokenPrice: false,
      tokenNumber: false
    },
    valid: {
      tokenTicker: true,
      tokenPrice: true,
      tokenNumber: true
    },
    form: {}
  }),
  props: {
    completed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('SuperAdmin', ['operator']),
    ...mapGetters('Onboarding', ['onboardingForm']),
    ...mapGetters('ClientsInfo', ['isListed']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapState('TokensInfo', {
      tokenPrice: (state: TokensInfoState) => state.tokenPrice.price,
      rateCurrencyTicker: (state: TokensInfoState) =>
        state.tokenPrice.rateCurrencyTicker || ''
    }),
    tiles(): any[] {
      return [
        {
          name: 'tokenTicker',
          icon: 'mdi-check',
          title: 'Loyalty Token',
          color: 'warning',
          value: this.form?.tokenTicker || ''
        },
        {
          name: 'tokenPrice',
          icon: 'mdi-currency-usd',
          title: 'Token Price',
          color: 'success',
          value: this.currentTokenPrice
        },
        {
          name: 'tokenNumber',
          icon: 'mdi-poll',
          title: 'Number of Tokens',
          color: '#8A8D93',
          value: this.$options.filters.numeralSpaces(
            this.form?.tokenNumber || 0,
            '0,0'
          )
        }
      ];
    },
    currentTokenPrice(): string {
      const isEditableTokenPrice = !this.isListed && this.isSuperAdmin;
      return `${this.$options.filters.numeralSpaces(
        isEditableTokenPrice
          ? this.onboardingForm.tokenPrice
          : this.tokenPrice,
        '0,0.[00000]'
      )} ${isEditableTokenPrice ? 'USD' : this.rateCurrencyTicker}`;
    }
  },
  watch: {
    onboardingForm: {
      handler(): void {
        this.form = { ...this.onboardingForm };
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('SuperAdmin', ['getOperatorDetailsById']),
    ...mapActions('Onboarding', ['getOnboardingFormData']),
    done(): void {
      window.dataLayer.push({
        event: 'token_info_section_finished',
        eventCategory: 'onboarding',
        eventAction: 'status_update',
        eventLabel: 'invite_developer_section_finished'
      });
      this.$emit('done');
    },
    isEditTile(name: string): boolean {
      return this.editTiles[name];
    },
    edit(name: string): void {
      this.editTiles[name] = true;
    },
    closeEdit(name: string): void {
      this.editTiles[name] = false;
    },
    discard(name: string): void {
      this.closeEdit(name);
      this.form[name] = this.onboardingForm[name];
    },
    save(name: string): void {
      if (this.$refs[name][0].validate()) {
        this.closeEdit(name);
        this.updateOperatorOnboarding({ [name]: this.form[name] });
      }
    },
    async updateOperatorOnboarding(data: {
      [key: string]: unknown;
    }): Promise<void> {
      await updateOperator({ ...this.onboardingForm, ...data })
        .then((data) => {
          this.$toast.success('Update success', data);
        })
        .catch((error) => {
          this.$toast.error('Update error', error);
        })
        .finally(() => {
          this.getOperatorDetailsById(this.operator.id);
          this.getOnboardingFormData();
        });
    }
  }
};
