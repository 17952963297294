

































































































import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import ExchangeRowsIcon from '@/assets/images/icons/exchange-rows-icon.svg?inline';
import FAQListItem from '@/components/FAQListItem.vue';
import OnboardingTokenExchangeContent from '@/views/OnboardingView/onboardingStepsComponents/onboardingContents/OnboardingTokenExchangeContent.vue';
import { EOnbordingStep } from '@/api/schema';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';
import ExternalLink from '@/components/links/ExternalLink.vue';

export default {
  name: 'OnboardingTokenExchange',
  mixins: [OnboardingMixin],
  components: {
    ExpansionProgressPanel,
    ExchangeRowsIcon,
    FAQListItem,
    OnboardingTokenExchangeContent,
    ExternalLink
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.tokenExchangeProgress,
    faqImages: [
      {
        name: 'onboarding',
        src: '/images/onboarding/faq-registration-token-exchange.webp',
        width: 200,
        height: 185
      },
      {
        name: 'onboarding',
        src: '/images/onboarding/faq-fo-deposit-token-exchange.webp',
        width: 200,
        height: 185
      },
      {
        name: 'onboarding',
        src: '/images/onboarding/faq-deposit-token-exchange.webp',
        width: 200,
        height: 185
      },
      {
        name: 'onboarding',
        src: '/images/onboarding/faq-withdrawal-token-exchange.webp',
        width: 200,
        height: 185
      }
    ]
  }),
  watch: {
    isExpanded(status: number | undefined): void {
      if (status === 0) {
        window.dataLayer.push({
          event: 'token_exchange_section_opened',
          eventCategory: 'onboarding',
          eventAction: 'opened',
          eventLabel: 'token_exchange_section_opened'
        });
      }
    }
  }
};
