







































































import { mapState, mapActions } from 'vuex';
import {
  required,
  ip as isIP,
  isIpAddress as isValidIp
} from '@/helpers/validation';
import type { GameProviderData } from '@/api/schema';
import type { PropType } from 'vue';

export default {
  name: 'OnboardingProviderDialog',
  props: {
    sendingForm: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },
  data(): any {
    return {
      formValid: true,
      providerData: {
        connected: false,
        ipAddresses: ['', ''],
        name: null,
        url: null
      }
    };
  },
  computed: {
    ...mapState('Onboarding', [
      'displayProviderDialog',
      'selectedProvider',
      'loading'
    ]),
    rules: function (): any {
      return {
        url: [required],
        ipAddress: [
          (v) => (v ? isIP(v) : true),
          (ip) =>
            !ip ||
            this.providerData?.ipAddresses?.filter((el) => el === ip)
              .length === 1 ||
            'Already used'
        ]
      };
    },
    deleteIcon(): string {
      return this.providerData.ipAddresses.length > 1
        ? 'mdi-close'
        : undefined;
    },
    isCreateEnabled(): boolean {
      return this.providerData.ipAddresses.some((ip) => isValidIp(ip));
    }
  },
  watch: {
    selectedProvider(newData: GameProviderData): void {
      this.$nextTick((): void => {
        const list = Array.isArray(newData.ipAddresses)
          ? newData.ipAddresses.filter((ip) => isValidIp(ip))
          : ['', ''];
        this.providerData = {
          ...newData,
          ipAddresses: list.length >= 2 ? list : [...list, '']
        };
        this.$refs.form?.resetValidation();
      });
    }
  },
  methods: {
    ...mapActions('Onboarding', ['hideProviderDialog']),
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick((): void => {
        if (this.formValid) {
          this.$emit('submit', {
            ...this.providerData,
            ipAddresses: this.providerData.ipAddresses.filter((ip) =>
              isValidIp(ip)
            )
          });
        }
      });
    },
    addIpAddressField(): void {
      this.providerData.ipAddresses.push('');
    },
    removeIpAddressField(index: number, ip: string): void {
      let filteredArray = this.providerData.ipAddresses.filter(
        (ipAddress, idx) => !(ipAddress === ip && idx === index)
      );

      const needFill = filteredArray.length < 2;

      if (needFill) {
        filteredArray.length = 2;
        filteredArray = filteredArray.map((el) => el || '');
      }

      this.providerData.ipAddresses = [...filteredArray];

      if (!ip.length || !this.selectedProvider.ipAddresses.includes(ip)) {
        return;
      }

      this.$emit('deleteIp', { name: this.providerData.name, ip });
    }
  }
};
