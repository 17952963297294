




















































































import ColorPicker from '@/components/core/ColorPicker.vue';
import BaseLoader from '@/components/core/BaseLoader.vue';
import CodeBlockWrapper from '@/components/code-blocks/CodeBlockWrapper.vue';
import { startCase } from 'lodash';
import { getWidgetTheme, setWidgetTheme } from '@/api/WidgetSettings';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { EColorsKey, IWidgetTheme, IWidgetThemeColor } from '@/api/schema';
import { AxiosError } from 'axios';
import isEqual from 'lodash/isEqual';

enum EThemeValue {
  THEME_0 = 'theme-0',
  THEME_1 = 'theme-1',
  THEME_2 = 'theme-2',
  THEME_3 = 'theme-3'
}

type TPickerColors = {
  [key in EColorsKey]: string;
};

interface IThemeData {
  colors: string[];
  pickersColors: TPickerColors;
  value: EThemeValue | null;
}

const DEFAULT_COLOR_VARS = Object.freeze(Object.values(EColorsKey));

const DEFAULT_PICKER_COLORS = Object.freeze(
  Object.values(EColorsKey).reduce((acc, key) => {
    acc[key] = '';
    return acc;
  }, {} as Record<EColorsKey, string>)
);

const DEFAULT_THEMES = Object.freeze([
  {
    colors: ['#131313', '#ffca42'],
    value: EThemeValue.THEME_0,
    pickersColors: {
      [EColorsKey.MAIN_COLOR]: '#ffca42',
      [EColorsKey.THEME_BACKGROUND_COLOR]: '#131313',
      [EColorsKey.TEXT_COLOR]: '#ffffff',
      [EColorsKey.THEME_BLOCK_BACKGROUND_COLOR]: '#202020',
      [EColorsKey.TEXT_COLOR_BUTTON]: '#ffffff',
      [EColorsKey.THEME_BLOCK_INNER_BACKGROUND_COLOR]: '#353535'
    }
  },
  {
    colors: ['#183c61', '#bd0d15'],
    value: EThemeValue.THEME_1,
    pickersColors: {
      [EColorsKey.MAIN_COLOR]: '#bd0d15',
      [EColorsKey.THEME_BACKGROUND_COLOR]: '#0c1823',
      [EColorsKey.TEXT_COLOR]: '#ffffff',
      [EColorsKey.THEME_BLOCK_BACKGROUND_COLOR]: '#16293c',
      [EColorsKey.TEXT_COLOR_BUTTON]: '#ffffff',
      [EColorsKey.THEME_BLOCK_INNER_BACKGROUND_COLOR]: '#183c61'
    }
  },
  {
    colors: ['#6116a0', '#ff6b00'],
    value: EThemeValue.THEME_2,
    pickersColors: {
      [EColorsKey.MAIN_COLOR]: '#ff6b00',
      [EColorsKey.THEME_BACKGROUND_COLOR]: '#22053a',
      [EColorsKey.TEXT_COLOR]: '#ffffff',
      [EColorsKey.THEME_BLOCK_BACKGROUND_COLOR]: '#390467',
      [EColorsKey.TEXT_COLOR_BUTTON]: '#ffffff',
      [EColorsKey.THEME_BLOCK_INNER_BACKGROUND_COLOR]: '#6116a0'
    }
  },
  {
    colors: ['#f0f5f8', '#1b73e8'],
    value: EThemeValue.THEME_3,
    pickersColors: {
      [EColorsKey.MAIN_COLOR]: '#1b73e8',
      [EColorsKey.THEME_BACKGROUND_COLOR]: '#f0f5f8',
      [EColorsKey.TEXT_COLOR]: '#000000',
      [EColorsKey.THEME_BLOCK_BACKGROUND_COLOR]: '#ffffff',
      [EColorsKey.TEXT_COLOR_BUTTON]: '#ffffff',
      [EColorsKey.THEME_BLOCK_INNER_BACKGROUND_COLOR]: '#f3f5f6'
    }
  }
]);

export default {
  name: 'OnboardingUISettingsCopyStakeContent',
  components: { ColorPicker, CodeBlockWrapper, BaseLoader },
  data(): unknown {
    return {
      EColorsKey,
      themeInit: null as EThemeValue | null,
      colorsInit: DEFAULT_PICKER_COLORS as TPickerColors,
      currentTheme: null as EThemeValue | null,
      currentColors: DEFAULT_PICKER_COLORS as TPickerColors,
      themes: DEFAULT_THEMES as IThemeData[],
      isIframeInit: false as boolean,
      isLoading: false as boolean
    };
  },
  computed: {
    copyStakeDemoLink(): string {
      const baseUrl = process.env.VUE_APP_API_URL;

      const url = new URL(baseUrl.replace('api', 'demo.copystake'));

      url.pathname += 'broadcast/a61212322d1b278fe0cf1813406c095e';
      url.searchParams.append('admin', 'true');

      return url.toString();
    },
    themeMode: {
      get(): string {
        return this.currentTheme ? 'default' : 'custom';
      },
      set(value: string): void {
        this.currentTheme =
          value === 'default' ? this.themeInit || EThemeValue.THEME_0 : null;

        if (value === 'default') {
          this.selectTheme(
            DEFAULT_THEMES.find(({ value }) => value === this.currentTheme)
          );
        }
      }
    },
    isCustomThemeEnabled(): boolean {
      return !this.currentTheme;
    },
    showControls(): boolean {
      return (
        this.$role.can.update('onboarding') ||
        this.$role.can.update('home colors')
      );
    },
    isUpdated(): boolean {
      const isUpdatedTheme = this.themeInit !== this.currentTheme;
      const isUpdatedColors = !isEqual(this.colorsInit, this.currentColors);

      return isUpdatedTheme || isUpdatedColors;
    }
  },
  mounted(): void {
    window.addEventListener('message', this.handlePostMessage);
  },
  beforeDestroy(): void {
    window.removeEventListener('message', this.handlePostMessage);
  },
  methods: {
    handlePostMessage(event: MessageEvent): void {
      const {
        data: { type }
      } = event;

      if (type === 'init-admin') {
        this.getSavedTheme();
      }
    },
    async getSavedTheme(): Promise<void> {
      this.isLoading = true;
      const data = {
        theme: null,
        pickersColors: DEFAULT_PICKER_COLORS
      };

      try {
        const themeData = await getWidgetTheme();

        data.theme = themeData
          ? themeData.value ?? null
          : DEFAULT_THEMES[0].value;

        if (Array.isArray(themeData.colors) && themeData.colors.length > 0) {
          data.pickersColors = this.getThemeColors(themeData.colors);
        } else if (themeData.value) {
          data.pickersColors = DEFAULT_THEMES.find(
            ({ value }) => value === themeData.value
          )?.pickersColors;
        } else {
          data.pickersColors = DEFAULT_THEMES[0].pickersColors;
        }
      } catch (e) {
        errorToastMessage(e);
        console.log('Operator no theme selected');
      } finally {
        this.isLoading = false;
        this.isIframeInit = true;
      }

      this.currentTheme = data.theme;
      this.currentColors = { ...data.pickersColors };

      this.themeInit = data.theme;
      this.colorsInit = { ...data.pickersColors };

      this.sendMessage({
        type: 'change-colors',
        value: this.currentColors
      });
    },
    getThemeColors(colors: IWidgetThemeColor[]): TPickerColors {
      return Object.fromEntries(
        DEFAULT_COLOR_VARS.map((type: EColorsKey) => [
          type,
          colors.find((color) => color.type === type)?.color
        ])
      ) as TPickerColors;
    },
    selectTheme(theme: IThemeData): void {
      this.currentTheme = theme.value;
      this.currentColors = { ...theme.pickersColors };

      this.changeTheme();
    },
    changeTheme(): void {
      this.sendMessage({ type: 'theme', value: this.currentTheme });
    },
    changeColors(key: EColorsKey, value: string): void {
      this.currentTheme = null;
      this.currentColors[key] = value;

      this.sendMessage({
        type: 'change-colors',
        value: this.currentColors
      });
    },
    formatLabel(name: EColorsKey): string {
      return name
        .split('-')
        .filter((n) => n !== 'theme')
        .map(startCase)
        .join(' ');
    },
    saveTheme(): void {
      this.isLoading = true;
      const themeData: IWidgetTheme = {
        value: this.currentTheme,
        colors: Object.entries(this.currentColors).map(([type, color]) => ({
          type,
          color
        })) as IWidgetThemeColor[]
      };

      setWidgetTheme(themeData)
        .then(() => {
          this.$toast.success(`CopyStake Page has been saved`);
          this.$emit('save');

          this.themeInit = this.currentTheme;
          this.colorsInit = { ...this.currentColors };
        })
        .catch((err: AxiosError) => {
          this.getSavedTheme();
          errorToastMessage(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    sendMessage(message: unknown): void {
      this.$refs.frame?.contentWindow.postMessage(message, '*');
    }
  }
};
