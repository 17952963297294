import { render, staticRenderFns } from "./OnboardingUserSupport.vue?vue&type=template&id=7152e874&scoped=true&lang=pug"
import script from "./OnboardingUserSupport.vue?vue&type=script&lang=ts"
export * from "./OnboardingUserSupport.vue?vue&type=script&lang=ts"
import style0 from "./OnboardingUserSupport.vue?vue&type=style&index=0&id=7152e874&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7152e874",
  null
  
)

export default component.exports