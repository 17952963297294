import { buildUrl } from '@/helpers/buildUrl';

// https://api.dev.trueplay.io/api/v1/copystake/admin/swagger-ui/index.html
export const BASE = (): string => '/api/v1/copystake/control';

export const BASE_OPERATOR = (): string => buildUrl(`${BASE()}/{operatorId}`);

// https://api.dev.trueplay.io/api/v1/copystake/api-gateway/webjars/swagger-ui/index.html
export const BASE_WEB = (): string => '/api/v1/copystake/web';

export const BASE_WEB_OPERATOR = (): string =>
  buildUrl(`${BASE_WEB()}/admin/{operatorId}`);

// https://api.dev1.trueplay.io/api/v1/copystake/api-gateway/webjars/swagger-ui/index.html?urls.primaryName=Streamers%20Game%20Center%20Service%20(Admin%20API)
export const BASE_GAME_CENTER = (): string => '/api/v1/copystake/game-center';

export const BASE_GAME_CENTER_OPERATOR = (): string =>
  buildUrl(`${BASE_GAME_CENTER()}/admin/{operatorId}`);
