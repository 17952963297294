






























































import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import ChainIcon from '@/assets/images/icons/chain-icon.svg?inline';
import FAQListItem from '@/components/FAQListItem.vue';
import OnboardingLoyaltyProgramImage from '@/assets/images/onboarding-loyalty-program.svg?inline';
import { EOnbordingStep } from '@/api/schema';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';
import ExternalLink from '@/components/links/ExternalLink.vue';

export default {
  name: 'OnboardingGetURL',
  mixins: [OnboardingMixin],
  components: {
    ExpansionProgressPanel,
    ChainIcon,
    FAQListItem,
    OnboardingLoyaltyProgramImage,
    ExternalLink
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.getUrlProgress,
    faqImages: [
      {
        name: 'onboarding',
        src: '/images/onboarding/faq-loyalty-account.webp',
        width: 252,
        height: 70
      }
    ]
  }),
  watch: {
    isExpanded(status: number | undefined): void {
      if (status === 0) {
        window.dataLayer.push({
          event: 'get_url_section_opened',
          eventCategory: 'onboarding',
          eventAction: 'opened',
          eventLabel: 'get_url_section_opened'
        });
      }
    }
  }
};
