



















































import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import FaceIcon from '@/assets/images/icons/face-icon.svg?inline';
import FAQListItem from '@/components/FAQListItem.vue';
import OnboardingAccountCreationContent from '@/views/OnboardingView/onboardingStepsComponents/onboardingContents/OnboardingAccountCreationContent.vue';
import { EOnbordingStep } from '@/api/schema';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';
import ExternalLink from '@/components/links/ExternalLink.vue';

export default {
  name: 'OnboardingAccountCreation',
  mixins: [OnboardingMixin],
  components: {
    FAQListItem,
    ExpansionProgressPanel,
    FaceIcon,
    OnboardingAccountCreationContent,
    ExternalLink
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.accountCreationProgress
  }),
  watch: {
    isExpanded(status: number | undefined): void {
      if (status === 0) {
        window.dataLayer.push({
          event: 'account_creation_section_opened',
          eventCategory: 'onboarding',
          eventAction: 'opened',
          eventLabel: 'account_creation_section_opened'
        });
      }
    }
  }
};
