






































































import OnboardingWhiteListIpContent from '@/views/OnboardingView/onboardingStepsComponents/onboardingContents/OnboardingWhiteListIpContent.vue';
import CodeBlock from '@/components/code-blocks/CodeBlock.vue';
import CodeBlockWrapper from '@/components/code-blocks/CodeBlockWrapper.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import * as vr from '@/helpers/validation';
import { updateOperator } from '@/api/Onboarding';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import EmailVerifyButton from '@/components/EmailVerifyButton.vue';
import { ELoyaltyFeatureFlag } from '@/store/modules/feature-flags/FeatureFlagNames';

const OnboardingCopystakeSettings = (): any =>
  import(
    '@/views/OnboardingView/onboardingStepsComponents/OnboardingCopystakeSettings.vue'
  );

export default {
  name: 'OnboardingIntegrationSettingsContent',
  components: {
    OnboardingWhiteListIpContent,
    CodeBlock,
    CodeBlockWrapper,
    EmailVerifyButton,
    OnboardingCopystakeSettings
  },
  data: (): unknown => ({
    formValid: true,
    baseUrl: '',
    urlHint: false,
    urlRules: [(v) => vr.required(v, 'Callback URL'), vr.urlWithoutProtocol]
  }),
  computed: {
    ...mapState('Onboarding', [
      'secret',
      'apiKey',
      'loading',
      'integrationUrl',
      'truePlayIPs',
      'operatorIPs',
      'proxyUrl'
    ]),
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('Onboarding', ['onboardingForm', 'isEmailVerified']),
    ...mapGetters('FeatureFlags', ['isEnabledFeatureFlag']),
    showCopyStakeSettings(): boolean {
      if (this.isSuperAdmin) return true;

      return this.isEnabledFeatureFlag(ELoyaltyFeatureFlag.COPY_STAKE);
    },
    operatorApiKey(): string {
      return (
        (!this.loading.apiKeys && this.apiKey) ||
        'Complete a questionary above to get the API key'
      );
    },
    url: {
      get(): string {
        const url = this.onboardingForm.url || this.baseUrl;
        return url.replace('https://', '');
      },
      set(v: string): void {
        this.baseUrl = 'https://' + v;
      }
    },
    urlToSendTransaction(): string {
      const url = this.proxyUrl?.replaceAll(' ', '').replace(/\/?$/, '');
      const suffix = '/api/v1/accept';

      return url?.endsWith(suffix) ? url : `${url}${suffix}`;
    },
    isDisabledSaveUrlBtn(): boolean {
      return (
        !this.$role.can.update('onboarding') ||
        !this.formValid ||
        !this.baseUrl ||
        this.baseUrl === this.onboardingForm.url
      );
    }
  },
  methods: {
    ...mapMutations('Onboarding', ['setOnboardingFormData']),
    updateOperatorInfo(): void {
      const form = this.$refs.form;
      form.validate();

      this.$nextTick((): void => {
        if (this.formValid) {
          const onboardingForm = {
            ...this.onboardingForm,
            ...{ url: this.baseUrl }
          };
          updateOperator(onboardingForm)
            .then(() => {
              this.$toast.success('Operator data updated!');
              this.setOnboardingFormData({ url: this.baseUrl });
              this.updateProgress();
              this.urlHint = true;
              setTimeout(() => (this.urlHint = false), 1000);
            })
            .catch(errorToastMessage);
        }
      });
    },
    updateProgress(): void {
      if (this.onboardingForm.url && this.operatorIPs.length) {
        this.$emit('updateProgress');
      }
    }
  }
};
