


































import OnboardingWelcomeImage from '@/assets/images/onboarding-welcome.svg?inline';
import ExternalLink from '@/components/links/ExternalLink.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'OnboardingWelcomeDescription',
  components: {
    OnboardingWelcomeImage,
    ExternalLink
  },
  computed: {
    ...mapGetters('Onboarding', ['isTestEnv']),
    ...mapGetters('app', ['isCopyStakeRole']),
    ...mapGetters('SuperAdmin', ['isCurrentCopyStakeAccount']),
    isCopyStakeAccount(): boolean {
      return this.isCopyStakeRole || this.isCurrentCopyStakeAccount;
    }
  }
};
