import { ERole } from '@/plugins/role-permissions/types';
import { isSuperAdmin } from '@/helpers/superAdminHelper';
import { isGameProviderAdmin } from '@/helpers/gameProviderAdminHelper';

const superAdminRoles = [
  ERole.ROLE_ADMIN,
  ERole.ROLE_OWNER,
  ERole.ROLE_FINANCE,
  ERole.ROLE_MANAGER
];

const adminRoles = [ERole.ROLE_ACCOUNT, ERole.ROLE_OPERATOR_ADMIN];

const financeRoles = [...adminRoles, ERole.ROLE_OPERATOR_FINANCE];

const managerRoles = [...adminRoles, ERole.ROLE_OPERATOR_MARKETER];

const adminCopyStakeRoles = [
  ERole.ROLE_COPY_STAKE_ACCOUNT,
  ERole.ROLE_OPERATOR_COPY_STAKE_ADMIN
];

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login'
    },
    component: (): Promise<any> =>
      import(/* webpackMode: "lazy" */ `@/views/AuthViews/LoginView.vue`)
  },
  ...(!isSuperAdmin
    ? [
        {
          path: '/account-verify',
          name: 'account-verify',
          meta: {
            title: 'Account Verify'
          },
          component: (): Promise<any> =>
            import(
              /* webpackMode: "lazy" */ `@/views/AuthViews/AccountVerifyView.vue`
            )
        },
        {
          path: '/invite-verify',
          name: 'invite-account',
          meta: {
            title: 'Create Account'
          },
          component: (): Promise<any> =>
            import(
              /* webpackMode: "lazy" */ `@/views/AuthViews/InviteVerifyView.vue`
            )
        },
        {
          path: '/unsubscribe',
          name: 'unsubscribe',
          meta: {
            title: 'Unsubscribe'
          },
          component: (): Promise<any> =>
            import(/* webpackMode: "lazy" */ `@/views/UnsubscribeView.vue`)
        },
        ...(isGameProviderAdmin
          ? []
          : [
              {
                path: '/registration',
                name: 'registration',
                meta: {
                  title: 'Registration'
                },
                component: (): Promise<any> =>
                  import(
                    /* webpackMode: "lazy" */ `@/views/AuthViews/CreateAccountView.vue`
                  )
              }
            ]),
        {
          path: '/password-forgot',
          name: 'password-forgot',
          meta: {
            title: 'Forgot Password'
          },
          component: (): Promise<any> =>
            import(
              /* webpackMode: "lazy" */ `@/views/AuthViews/PasswordForgotView.vue`
            )
        },
        {
          path: '/password-recovery',
          name: 'password-recovery',
          meta: {
            title: 'Recovery Password'
          },
          component: (): Promise<any> =>
            import(
              /* webpackMode: "lazy" */ `@/views/AuthViews/PasswordRecoveryView.vue`
            )
        }
      ]
    : []),
  {
    path: '/',
    component: (): Promise<any> =>
      import(/* webpackMode: "lazy" */ `@/views/MainView/MainView.vue`),
    children: [
      {
        path: 'users',
        name: 'users',
        meta: {
          title: 'Users',
          authorize: [
            ERole.ROLE_ACCOUNT,
            ERole.ROLE_OPERATOR_ADMIN,
            ERole.ROLE_OPERATOR_SUPPORT,
            ERole.ROLE_OPERATOR_GUEST,
            ERole.ROLE_OPERATOR_FINANCE,
            ERole.ROLE_OPERATOR_VIEWER,
            ERole.ROLE_COPY_STAKE_ACCOUNT,
            ERole.ROLE_OPERATOR_COPY_STAKE_ADMIN,
            ERole.ROLE_OPERATOR_COPY_STAKE_SUPPORT,
            ERole.ROLE_OPERATOR_MARKETER
          ]
        },
        component: (): Promise<any> =>
          import(
            /* webpackMode: "lazy" */ '@/views/DashboardViews/UsersView.vue'
          )
      },
      {
        name: 'user-management',
        path: 'users/:userId',
        meta: {
          title: 'User management',
          authorize: [
            ERole.ROLE_ACCOUNT,
            ERole.ROLE_OPERATOR_ADMIN,
            ERole.ROLE_OPERATOR_SUPPORT,
            ERole.ROLE_OPERATOR_GUEST,
            ERole.ROLE_OPERATOR_FINANCE,
            ERole.ROLE_OPERATOR_VIEWER,
            ERole.ROLE_OPERATOR_MARKETER
          ]
        },
        component: (): Promise<any> =>
          import(
            /* webpackMode: "lazy" */ '@/views/DashboardViews/user-management/UserManagement.vue'
          )
      },
      {
        name: 'withdraw-request-item',
        path: 'withdrawal-requests/:operationId',
        meta: {
          title: 'Withdrawal Request Item',
          authorize: [
            ...financeRoles,
            ERole.ROLE_OPERATOR_SUPPORT,
            ERole.ROLE_OPERATOR_GUEST,
            ERole.ROLE_OPERATOR_VIEWER,
            ...superAdminRoles
          ]
        },
        component: (): Promise<any> =>
          import(
            /* webpackMode: "lazy" */ '@/views/WithdrawRequestsView/WithdrawRequestItemView.vue'
          )
      },
      {
        path: 'statistics',
        name: 'statistics',
        meta: {
          title: 'Statistics',
          authorize: [
            ...financeRoles,
            ...superAdminRoles,
            ERole.ROLE_OPERATOR_MARKETER,
            ERole.ROLE_OPERATOR_SUPPORT,
            ERole.ROLE_OPERATOR_GUEST,
            ERole.ROLE_OPERATOR_VIEWER
          ]
        },
        props: true,
        component: (): Promise<any> =>
          import(
            /* webpackMode: "lazy" */ `@/views/StatisticsView/StatisticsView.vue`
          )
      },
      {
        name: 'not-allowed',
        path: 'not-allowed',
        meta: {
          title: 'Access denied',
          requiresAuth: true
        },
        component: (): Promise<any> =>
          import(/* webpackMode: "lazy" */ `@/components/error/NotAllowed.vue`)
      },
      ...(isSuperAdmin
        ? [
            {
              name: 'roles',
              path: 'roles',
              meta: {
                title: 'Admin panel - Roles',
                authorize: [ERole.ROLE_ADMIN, ERole.ROLE_OWNER]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/SuperAdminViews/super-admin-roles/SuperAdminRolesView.vue`
                )
            },
            {
              name: 'accounts',
              path: 'accounts',
              meta: {
                title: 'Admin panel - Accounts',
                authorize: superAdminRoles
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/SuperAdminViews/super-admin-accounts/SuperAdminAccountsView.vue`
                )
            },
            {
              name: 'account-operator',
              path: 'accounts/:id/:operatorId/',
              meta: {
                title: 'Account - operator data',
                authorize: superAdminRoles
              },
              redirect: {
                name: 'account-operator-balance'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/SuperAdminViews/super-admin-operator-view/SuperAdminOperatorView.vue'
                ),
              children: [
                {
                  name: 'account-operator-balance',
                  path: 'operator-balance',
                  meta: {
                    title: 'Account - Operator balance'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/OperatorBalanceView/OperatorPaymentsView.vue'
                    )
                },
                {
                  name: 'account-operator-home',
                  path: 'home',
                  meta: {
                    title: 'Account - Home'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/OnboardingView/OnboardingView.vue'
                    )
                },
                {
                  name: 'account-operator-loyalty-promotion',
                  path: 'loyalty-promotion',
                  meta: {
                    title: 'Account - Loyalty promotion'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ `@/views/LoyaltyPromotionView/LoyaltyPromotionView.vue`
                    )
                },
                {
                  name: 'account-operator-dashboard',
                  path: 'dashboard',
                  meta: {
                    title: 'Account - Dashboard'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ `@/views/DashboardViews/dashboard-view/DashboardView.vue`
                    )
                },
                {
                  name: 'account-operator-programs',
                  path: 'programs',
                  meta: {
                    title: 'Account - Programs'
                  },
                  redirect: {
                    name: 'account-operator-programs-p2e'
                  }
                },
                {
                  name: 'account-operator-programs-p2e',
                  path: 'programs/play-to-earn',
                  meta: {
                    title: 'Account - Play to Earn'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/DashboardViews/mining-view/MiningView.vue'
                    )
                },
                {
                  name: 'account-operator-programs-h2e',
                  path: 'programs/hold-to-earn',
                  meta: {
                    title: 'Account - Hold to Earn'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/DashboardViews/staking-view/StakingView.vue'
                    )
                },
                {
                  name: 'account-operator-programs-promo',
                  path: 'programs/marketing-campaigns',
                  meta: {
                    title: 'Account - Marketing Campaigns'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/PromoView/PromoView.vue'
                    )
                },
                {
                  name: 'account-operator-missions',
                  path: 'programs/missions',
                  meta: {
                    title: 'Account - Missions'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/MissionsView/MissionsView.vue'
                    )
                },
                {
                  name: 'account-operator-mission-create',
                  path: 'programs/missions/create',
                  meta: {
                    title: 'Account - Create mission'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/MissionsView/MissionDetailsView.vue'
                    )
                },
                {
                  name: 'account-operator-mission-view',
                  path: 'programs/missions/:missionId',
                  meta: {
                    title: 'Account - Missions Details',
                    readonly: true
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/MissionsView/MissionDetailsView.vue'
                    )
                },
                {
                  name: 'account-operator-mission-edit',
                  path: 'programs/missions/edit/:missionId',
                  meta: {
                    title: 'Account - Edit mission'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/MissionsView/MissionDetailsView.vue'
                    )
                },
                {
                  name: 'account-operator-token',
                  path: 'token',
                  meta: {
                    title: 'Account - Token'
                  },
                  redirect: {
                    name: 'account-operator-token-users'
                  }
                },
                {
                  name: 'account-operator-token-users',
                  path: 'token/users',
                  meta: {
                    title: 'Account - Users'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/DashboardViews/UsersView.vue'
                    )
                },
                {
                  name: 'account-operator-token-user-management',
                  path: 'token/users/:userId',
                  meta: {
                    title: 'Account - User'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/DashboardViews/user-management/UserManagement.vue'
                    )
                },
                {
                  name: 'account-operator-token-management',
                  path: 'token/token-management',
                  meta: {
                    title: 'Account - Token Management'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/DashboardViews/token-management/TokenManagementView.vue'
                    )
                },
                {
                  name: 'account-operator-token-payment-limits',
                  path: 'token/payment-limits',
                  meta: {
                    title: 'Account - Payment limits'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/PaymentsView/PaymentsView.vue'
                    )
                },
                {
                  name: 'account-operator-token-withdraw-requests',
                  path: 'token/withdrawal-requests',
                  meta: {
                    title: 'Account - Withdrawal requests'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/WithdrawRequestsView/WithdrawRequestsView.vue'
                    ),
                  beforeEnter: (to, from, next) => {
                    if (from.name && to.hash) {
                      next({
                        ...to,
                        hash: ''
                      });
                    } else {
                      next();
                    }
                  }
                },
                {
                  name: 'account-operator-token-withdraw-request-item',
                  path: 'token/withdrawal-requests/:operationId',
                  meta: {
                    title: 'Account - Withdrawal request'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/WithdrawRequestsView/WithdrawRequestItemView.vue'
                    )
                },
                {
                  name: 'account-operator-token-sale',
                  path: 'token/token-sale',
                  meta: {
                    title: 'Account - Token sale'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/DashboardViews/TokensaleView.vue'
                    )
                },
                {
                  name: 'account-operator-token-stats',
                  path: 'token/stats',
                  meta: {
                    title: 'Account - Token statistic'
                  },
                  props: true,
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/StatisticsView/StatisticsView.vue'
                    )
                },
                {
                  name: 'account-operator-copy-stake',
                  path: 'copy-stake',
                  meta: {
                    title: 'Account - CopyStake'
                  },
                  redirect: {
                    name: 'account-operator-copy-stake-streamers'
                  }
                },
                {
                  name: 'account-operator-copy-stake-guidance',
                  path: 'copy-stake/guidance',
                  meta: {
                    title: 'Account - CopyStake Guidance'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeGuidanceView.vue'
                    )
                },
                {
                  name: 'account-operator-copy-stake-streamers',
                  path: 'copy-stake/streamers',
                  meta: {
                    title: 'Account - Streamers'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeStreamersView.vue'
                    )
                },
                {
                  name: 'account-operator-copy-stake-web-streamers',
                  path: 'copy-stake/become-a-streamer',
                  meta: {
                    title: 'Account - Become a Streamer'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeWebStreamersView.vue'
                    )
                },
                {
                  name: 'account-operator-copy-stake-dashboard',
                  path: 'copy-stake/dashboard',
                  meta: {
                    title: 'Account - CopyStake Dashboard'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeDashboardView.vue'
                    )
                },
                {
                  name: 'account-operator-copy-stake-stats',
                  path: 'copy-stake/stats',
                  meta: {
                    title: 'Account - Streamers statistic'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeStatsView.vue'
                    )
                },
                {
                  name: 'account-operator-copy-stake-streaming-schedule',
                  path: 'copy-stake/streaming-schedule',
                  meta: {
                    title: 'Account - Streaming Schedule'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeStreamingScheduleView.vue'
                    )
                },
                {
                  name: 'account-operator-copy-stake-game-providers',
                  path: 'copy-stake/game-providers',
                  meta: {
                    title: 'Account - CopyStake Game Providers'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeGameProvidersView.vue'
                    )
                },
                {
                  name: 'account-operator-copy-stake-game-whitelist',
                  path: 'copy-stake/game-whitelist',
                  meta: {
                    title: 'Account - CopyStake Game Whitelist'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeWhitelistView.vue'
                    )
                },
                {
                  name: 'account-operator-copy-stake-user-blacklist',
                  path: 'copy-stake/user-blacklist',
                  meta: {
                    title: 'Account - CopyStake Players Blacklist'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeUserBlacklistView.vue'
                    )
                },
                {
                  name: 'account-operator-copy-stake-gamification-center',
                  path: 'copy-stake/gamification-center',
                  meta: {
                    title: 'Account - Gamification Center',
                    authorize: [ERole.ROLE_ADMIN, ERole.ROLE_OWNER]
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeGamificationCenter/CopyStakeGamificationCenterView.vue'
                    )
                },
                {
                  name: 'account-operator-settings',
                  path: 'settings',
                  meta: {
                    title: 'Account - Settings'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/SettingsViews/SettingsView.vue'
                    )
                }
              ]
            },
            {
              path: '/users/:userId',
              name: 'SuperAdminUsers',
              meta: {
                title: 'Admin panel - User management',
                authorize: superAdminRoles
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/DashboardViews/user-management/UserManagement.vue`
                )
            },
            {
              name: 'providers',
              path: 'providers',
              meta: {
                title: 'Admin panel - Game Providers',
                authorize: superAdminRoles
              },
              redirect: {
                name: 'providers-proxy-integration'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/SuperAdminViews/super-admin-providers/SuperAdminProvidersView.vue`
                ),
              children: [
                {
                  name: 'providers-proxy-integration',
                  path: 'proxy-integration',
                  meta: {
                    title: 'Game Providers - Proxy Integration'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/SuperAdminViews/super-admin-providers/SuperAdminProvidersProxyIntegration.vue'
                    )
                },
                {
                  name: 'providers-accounts',
                  path: 'accounts',
                  meta: {
                    title: 'Game Providers - Accounts'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/SuperAdminViews/super-admin-providers/SuperAdminProvidersAccounts.vue'
                    )
                }
              ]
            },
            {
              name: 'game-provider',
              path: 'providers/:id',
              meta: {
                title: 'Admin panel - Game Provider',
                authorize: superAdminRoles
              },
              redirect: {
                name: 'game-provider-copy-stake-stats'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/SuperAdminViews/super-admin-game-provider/SuperAdminGameProviderView.vue`
                ),
              children: [
                {
                  name: 'game-provider-copy-stake-stats',
                  path: 'copy-stake-stats',
                  meta: {
                    title: 'Game Provider - CopyStake Stats'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/SuperAdminViews/super-admin-game-provider/SuperAdminGameProviderCopyStakeStats.vue'
                    )
                },
                {
                  name: 'game-provider-reporting-api',
                  path: 'reporting-api',
                  meta: {
                    title: 'Game Provider - Reporting API'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/SuperAdminViews/super-admin-game-provider/SuperAdminGameProviderReportingApi.vue'
                    )
                },
                {
                  name: 'game-provider-settings',
                  path: 'settings',
                  meta: {
                    title: 'Game Provider - Settings'
                  },
                  component: (): Promise<any> =>
                    import(
                      /* webpackMode: "lazy" */ '@/views/SuperAdminViews/super-admin-game-provider/SuperAdminGameProviderSettings.vue'
                    )
                }
              ]
            },
            {
              name: 'custody',
              path: 'custody',
              meta: {
                title: 'Admin panel - Custody',
                authorize: superAdminRoles
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/SuperAdminViews/super-admin-custody/SuperAdminCustodyView.vue`
                )
            },
            {
              name: 'invoices',
              path: 'invoices',
              meta: {
                title: 'Admin panel - Invoices',
                authorize: superAdminRoles
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/SuperAdminViews/super-admin-invoices/SuperAdminInvoicesView.vue`
                )
            },
            {
              name: 'merchant',
              path: 'merchant/:merchantId',
              meta: {
                title: 'Admin panel - Merchant',
                authorize: superAdminRoles
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/SuperAdminViews/super-admin-merchant/SuperAdminMerchantView.vue`
                )
            },
            {
              name: 'merchant-wallet-history',
              path: 'merchant/:merchantId/wallet-history/:userId',
              meta: {
                title: 'Admin panel - Merchant Wallet History',
                authorize: superAdminRoles
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/SuperAdminViews/super-admin-merchant/SuperAdminMerchantWalletHistoryView.vue`
                )
            },
            {
              name: 'guidance',
              path: 'guidance',
              meta: {
                title: 'Admin panel - Guidance',
                authorize: superAdminRoles
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/SuperAdminViews/super-admin-guidance/SuperAdminGuidanceView.vue`
                )
            },
            {
              name: 'configuration',
              path: 'configuration',
              meta: {
                title: 'Admin panel - Configuration',
                authorize: [ERole.ROLE_ADMIN, ERole.ROLE_OWNER]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/SuperAdminViews/super-admin-configuration/SuperAdminConfigurationView.vue`
                )
            }
          ]
        : [
            {
              path: 'dashboard',
              name: 'dashboard',
              meta: {
                title: 'Dashboard',
                authorize: [
                  ...financeRoles,
                  ERole.ROLE_OPERATOR_MARKETER,
                  ERole.ROLE_OPERATOR_VIEWER
                ]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/DashboardViews/dashboard-view/DashboardView.vue`
                )
            },
            {
              path: 'copy-stake/guidance',
              name: 'copy-stake-guidance',
              meta: {
                title: 'Guidance'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeGuidanceView.vue'
                )
            },
            {
              path: 'copy-stake/streamers',
              name: 'copy-stake-streamers',
              meta: {
                title: 'CopyStake Streamers'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeStreamersView.vue'
                )
            },
            {
              path: 'copy-stake/become-a-streamer',
              name: 'copy-stake-web-streamers',
              meta: {
                title: 'CopyStake Become a Streamer'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeWebStreamersView.vue'
                )
            },
            {
              path: 'copy-stake/dashboard',
              name: 'copy-stake-dashboard',
              meta: {
                title: 'CopyStake Dashboard'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeDashboardView.vue'
                )
            },
            {
              path: 'copy-stake/stats',
              name: 'copy-stake-stats',
              meta: {
                title: 'CopyStake Stats'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeStatsView.vue'
                )
            },
            {
              path: 'copy-stake/streaming-schedule',
              name: 'copy-stake-streaming-schedule',
              meta: {
                title: 'CopyStake Streaming Schedule'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeStreamingScheduleView.vue'
                )
            },
            {
              path: 'copy-stake/game-providers',
              name: 'copy-stake-game-providers',
              meta: {
                title: 'CopyStake Game Providers'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeGameProvidersView.vue'
                )
            },
            {
              path: 'copy-stake/game-whitelist',
              name: 'copy-stake-game-whitelist',
              meta: {
                title: 'CopyStake Game Whitelist'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeWhitelistView.vue'
                )
            },
            {
              path: 'copy-stake/user-blacklist',
              name: 'copy-stake-user-blacklist',
              meta: {
                title: 'Players Blacklist'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeUserBlacklistView.vue'
                )
            },
            {
              path: 'copy-stake/gamification-center',
              name: 'copy-stake-gamification-center',
              meta: {
                title: 'CopyStake Gamification Center',
                authorize: [...adminRoles, ...adminCopyStakeRoles]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/CopyStake/CopyStakeGamificationCenter/CopyStakeGamificationCenterView.vue'
                )
            },
            {
              path: 'transaction-history',
              meta: {
                title: 'Transaction history'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/DashboardViews/transaction-history-view/TransactionHistory.vue`
                )
            },
            {
              path: 'play-to-earn',
              name: 'play-to-earn',
              meta: {
                title: 'Play to Earn',
                authorize: managerRoles
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/DashboardViews/mining-view/MiningView.vue`
                )
            },
            {
              path: 'play-to-earn-events',
              name: 'play-to-earn-events',
              meta: {
                title: 'Play to Earn Events',
                authorize: managerRoles
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/DashboardViews/PlayToEarnEvents/PlayToEarnEventsView.vue`
                )
            },
            {
              path: 'demo-widget',
              name: 'demo-widget',
              meta: {
                title: 'Demo widget',
                authorize: managerRoles
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/WidgetViews/WidgetViews.vue`
                )
            },
            {
              path: 'billing-info',
              name: 'billing-info',
              meta: {
                title: 'Billing Info',
                authorize: [
                  ...financeRoles,
                  ERole.ROLE_OPERATOR_MARKETER,
                  ERole.ROLE_OPERATOR_VIEWER
                ]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/OperatorBalanceView/OperatorPaymentsView.vue`
                )
            },
            {
              path: 'token-management',
              name: 'token-management',
              meta: {
                title: 'Token Management',
                authorize: [...managerRoles, ERole.ROLE_OPERATOR_VIEWER]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/DashboardViews/token-management/TokenManagementView.vue`
                )
            },
            {
              path: 'staking',
              name: 'staking',
              meta: {
                title: 'Hold to Earn',
                authorize: managerRoles
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/DashboardViews/staking-view/StakingView.vue`
                )
            },
            {
              path: 'token-sale',
              name: 'token-sale',
              meta: {
                title: 'Token Sale',
                authorize: [...managerRoles, ERole.ROLE_OPERATOR_VIEWER]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/DashboardViews/TokensaleView.vue`
                )
            },
            {
              path: 'home',
              name: 'home',
              meta: {
                name: 'Onboarding process',
                authorize: [
                  ...managerRoles,
                  ...adminCopyStakeRoles,
                  ERole.ROLE_OPERATOR_DEVELOPER
                ]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/OnboardingView/OnboardingView.vue`
                )
            },
            {
              name: 'settings',
              path: 'settings',
              meta: {
                title: 'Settings',
                authorize: [...managerRoles, ...adminCopyStakeRoles]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/SettingsViews/SettingsView.vue`
                )
            },
            {
              path: 'payments',
              name: 'payments',
              meta: {
                title: 'Payments',
                authorize: [
                  ...financeRoles,
                  ERole.ROLE_OPERATOR_SUPPORT,
                  ERole.ROLE_OPERATOR_GUEST,
                  ERole.ROLE_OPERATOR_VIEWER
                ]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/PaymentsView/PaymentsView.vue`
                )
            },
            {
              name: 'withdraw-requests',
              path: 'withdrawal-requests',
              meta: {
                title: 'Withdrawal Requests',
                authorize: [
                  ...financeRoles,
                  ERole.ROLE_OPERATOR_SUPPORT,
                  ERole.ROLE_OPERATOR_GUEST,
                  ERole.ROLE_OPERATOR_VIEWER
                ]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/WithdrawRequestsView/WithdrawRequestsView.vue`
                )
            },
            {
              path: 'promo',
              name: 'promo',
              meta: {
                title: 'Marketing Campaigns',
                authorize: [
                  ...financeRoles,
                  ERole.ROLE_OPERATOR_MARKETER,
                  ERole.ROLE_OPERATOR_VIEWER
                ]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/PromoView/PromoView.vue`
                )
            },
            {
              path: 'loyalty-promotion',
              name: 'loyalty-promotion',
              meta: {
                title: 'Loyalty promotion',
                authorize: managerRoles
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/LoyaltyPromotionView/LoyaltyPromotionView.vue`
                )
            },
            {
              path: 'copy-stake-stats',
              name: 'game-provider-admin-copy-stake-stats',
              meta: {
                title: 'CopyStake Stats',
                authorize: [
                  ERole.ROLE_GAME_PROVIDER,
                  ERole.ROLE_OPERATOR_VIEWER
                ]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/GameProviderViews/GameProviderCopyStakeStatsView.vue`
                )
            },
            {
              path: 'reporting-api',
              name: 'game-provider-admin-reporting-api',
              meta: {
                title: 'Reporting API',
                authorize: [
                  ERole.ROLE_GAME_PROVIDER,
                  ERole.ROLE_OPERATOR_VIEWER
                ]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/GameProviderViews/GameProviderReportingApiView.vue`
                )
            },
            {
              path: 'settings',
              name: 'game-provider-admin-settings',
              meta: {
                title: 'Settings',
                authorize: [ERole.ROLE_GAME_PROVIDER]
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ `@/views/GameProviderViews/GameProviderSettingsView.vue`
                )
            },
            {
              name: 'account-operator-missions',
              path: 'missions',
              meta: {
                title: 'Account - Missions'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/MissionsView/MissionsView.vue'
                )
            },
            {
              name: 'account-operator-mission-create',
              path: 'missions/create',
              meta: {
                title: 'Account - Create mission'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/MissionsView/MissionDetailsView.vue'
                )
            },
            {
              name: 'account-operator-mission-view',
              path: 'missions/:missionId',
              meta: {
                title: 'Account - Missions Details',
                readonly: true
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/MissionsView/MissionDetailsView.vue'
                )
            },
            {
              name: 'account-operator-mission-edit',
              path: 'missions/edit/:missionId',
              meta: {
                title: 'Account - Edit mission'
              },
              component: (): Promise<any> =>
                import(
                  /* webpackMode: "lazy" */ '@/views/MissionsView/MissionDetailsView.vue'
                )
            }
          ])
    ]
  },
  {
    path: '*',
    redirect: {
      name: 'catchAll',
      path: '/'
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:catchAll(.*)',
    component: (): Promise<any> =>
      import(/* webpackMode: "lazy" */ `@/components/error/NotFound.vue`),
    name: 'NotFound'
  }
];
