import { isSuperAdmin } from '@/helpers/superAdminHelper';

import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import type {
  ILoyaltyFeatureFlagItem,
  EFeatureFlagType,
  FeatureFlagABTestParams,
  ManageIdsFeatureFlagPayload
} from '@/api/schema';

/**
 * Get operator feature flags.
 * @return Promise with operator feature flags.
 */
export async function getOperatorLoyaltyFeatureFlags(): Promise<
  ILoyaltyFeatureFlagItem[]
> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/feature-flag`), {
      cacheTime: 0
    })
    .then(
      ({ data }: AxiosResponse): ILoyaltyFeatureFlagItem[] =>
        data.operatorFeatureFlags
    );
}

/**
 * Get operator feature flags as super-admin.
 * @return Promise with operator feature flags (by type).
 */
export async function adminGetOperatorLoyaltyFeatureFlags(
  type?: EFeatureFlagType
): Promise<ILoyaltyFeatureFlagItem[]> {
  return http
    .get(buildUrl(`/api/v1/{operatorId}/super-admin/feature-flag`), {
      ...(type && { params: { type } }),
      cacheTime: 0
    })
    .then(
      ({ data }: AxiosResponse): ILoyaltyFeatureFlagItem[] =>
        data.operatorFeatureFlags
    );
}

/**
 * Enable/disable operator feature flag
 */
export async function enableOperatorFeatureFlag(
  featureId: number,
  enable: boolean
): Promise<unknown> {
  return http.put(
    buildUrl(
      `/api/v1/{operatorId}/${
        isSuperAdmin ? 'super-admin/' : ''
      }feature-flag/${featureId}${isSuperAdmin ? '/enable' : ''}`
    ),
    null,
    {
      params: { enable }
    }
  );
}

/**
 * Visible/hidden for operator feature flag as super-admin.
 */
export async function adminVisibleOperatorFeatureFlag(
  featureId: number,
  visible: boolean
): Promise<unknown> {
  return http.put(
    buildUrl(
      `/api/v1/{operatorId}/super-admin/feature-flag/${featureId}/admin-visible`
    ),
    null,
    {
      params: { 'admin-visible': visible }
    }
  );
}

/**
 * Manage listType and add ids to operator feature flag.
 */
export async function manageOperatorFeatureFlag(
  featureId: number,
  payload: ManageIdsFeatureFlagPayload
): Promise<unknown> {
  return http.post(
    buildUrl(
      `/api/v1/{operatorId}/${
        isSuperAdmin ? 'super-admin/' : ''
      }feature-flag/${featureId}`
    ),
    payload
  );
}

/**
 * Delete ids to operator feature flag.
 */
export async function deleteManageOperatorFeatureFlag(
  featureId: number,
  payload: ManageIdsFeatureFlagPayload
): Promise<unknown> {
  return http.delete(
    buildUrl(
      `/api/v1/{operatorId}/${
        isSuperAdmin ? 'super-admin/' : ''
      }feature-flag/${featureId}`
    ),
    { data: payload }
  );
}

/**
 * Manage ab-test feature flag params.
 */
export async function abTestManageFeatureFlag(
  featureId: number,
  params: FeatureFlagABTestParams
): Promise<unknown> {
  return http.put(
    buildUrl(
      `/api/v1/{operatorId}/${
        isSuperAdmin ? 'super-admin/' : ''
      }feature-flag/${featureId}/ab-test`
    ),
    null,
    {
      params: { ...params }
    }
  );
}
