












import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import InviteDevIcon from '@/assets/images/icons/onbording-dev-icon.svg?inline';
import { EOnbordingStep } from '@/api/schema';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';
import InviteUserInput from '@/components/invite-user/InviteUserInput.vue';
import { ERole } from '@/api/schema';

export default {
  name: 'OnboardingInviteDeveloper',
  mixins: [OnboardingMixin],
  components: {
    ExpansionProgressPanel,
    InviteUserInput,
    InviteDevIcon
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.inviteDevProgress,
    email: '',
    type: ERole.ROLE_OPERATOR_DEVELOPER
  }),
  watch: {
    isExpanded(status: number | undefined): void {
      if (status === 0) {
        window.dataLayer.push({
          event: 'invite_developer_section_opened',
          eventCategory: 'onboarding',
          eventAction: 'opened',
          eventLabel: 'invite_developer_section_opened'
        });
      }
    }
  },
  methods: {
    handleInvite(): void {
      this.$emit('updateProgress', this.onboardingStepName);
    }
  }
};
