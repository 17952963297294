import { http } from './Connect';
import type { AxiosResponse } from 'axios';
import { buildUrl } from '@/helpers/buildUrl';
import type {
  OperatorOnboardingResponseData,
  OperatorOnboardingData,
  GameProviderData,
  OperatorApiKeysData,
  OperatorIPAddress,
  OperatorMode,
  OperatorIPProcessResponse,
  AccountOperator,
  WidgetUrlResponce,
  AccountInfo,
  OperatorOnboardingForm,
  WidgetDemoURL,
  TOnboardingProgress,
  TOnboardingStepPayload,
  IUserInfo,
  IInvitedUsers
} from './schema';

/**
 * Get account data
 * @return account data
 */
export async function accountInfo(): Promise<AccountInfo> {
  return http
    .get(`/api/v1/operator-onboarding/account`)
    .then(({ data }: AxiosResponse): AccountInfo => data);
}

/**
 * Get user data
 * @return user data
 */
export const getUserInfo = (): Promise<IUserInfo> =>
  http
    .get('/api/v2/admin/info')
    .then(({ data }: AxiosResponse): IUserInfo => data);

/**
 * Create operator.
 * @return new operator data.
 */
export async function createOperator(
  data: OperatorOnboardingForm
): Promise<OperatorOnboardingResponseData> {
  return http
    .post(`/api/v1/operator-onboarding`, data)
    .then(({ data }: AxiosResponse): OperatorOnboardingResponseData => data);
}

/**
 * Update operator.
 * @return operator data.
 */
export async function updateOperator(
  data: OperatorOnboardingForm,
  operatorId?: string
): Promise<OperatorOnboardingResponseData> {
  return http
    .put(
      buildUrl(`/api/v1/operator-onboarding/${operatorId || '{operatorId}'}`),
      data
    )
    .then(({ data }: AxiosResponse): OperatorOnboardingResponseData => data);
}

/**
 * Get game providers.
 * @return providers data.
 */
export async function getProviders(): Promise<GameProviderData[]> {
  return http
    .get(buildUrl(`/api/v1/operator-onboarding/{operatorId}/provider`))
    .then(({ data }: AxiosResponse): GameProviderData[] => data);
}

/**
 * Get operator api keys.
 * @return api keys data.
 */
export async function getApiKeys(): Promise<OperatorApiKeysData> {
  return http
    .get(buildUrl(`/api/v1/operator-onboarding/{operatorId}/api-key`))
    .then(({ data }: AxiosResponse): OperatorApiKeysData => data);
}

/**
 * Add game provider.
 */
export async function addGameProvider(
  data: GameProviderData
): Promise<unknown> {
  return http.post(
    buildUrl(`/api/v1/operator-onboarding/{operatorId}/provider`),
    { ...data }
  );
}

/**
 * Delete game provider
 */
export async function deleteGameProviderIp({
  name: provider,
  ip: address
}: {
  name: string;
  ip: string;
}): Promise<unknown> {
  return http.delete(
    buildUrl(
      `/api/v1/operator-onboarding/{operatorId}/provider/${provider}/ip`
    ),
    {
      params: {
        address
      }
    }
  );
}

/**
 * Get operator's onboarding form info.
 */
export async function getFormInfo(
  operatorId?: string
): Promise<OperatorOnboardingData> {
  return http
    .get(
      buildUrl(
        `/api/v1/operator-onboarding/${operatorId || '{operatorId}'}/form`
      )
    )
    .then(({ data }: AxiosResponse): OperatorOnboardingData => data);
}

/**
 * Get TruePlay ip addresses.
 * @returns default TruePlay ip addresses.
 */
export async function getTruePlayIPAddresses(): Promise<string[]> {
  return http
    .get(`api/v1/operator-onboarding/ip-address`)
    .then(({ data }: AxiosResponse): string[] => data);
}

/**
 * Get operator ip addresses.
 * @returns operator ip addresses.
 */
export async function getOperatorIPAddresses(
  operatorId?: string
): Promise<OperatorIPAddress[]> {
  return http
    .get(
      buildUrl(`api/v1/operator/${operatorId || '{operatorId}'}/ip-address`),
      {
        cacheTime: 0
      }
    )
    .then(({ data }: AxiosResponse): OperatorIPAddress[] => data);
}

/**
 * Delete operator ip addresses.
 * @returns deleted operator ip address.
 */
export async function deleteOperatorIPAddress(
  id: number
): Promise<OperatorIPProcessResponse> {
  return http
    .delete(buildUrl(`api/v1/operator/{operatorId}/ip-address/${id}`))
    .then(({ data }: AxiosResponse): OperatorIPProcessResponse => data);
}

/**
 * Create operator ip address.
 * @returns created operator ip address id.
 */
export async function createOperatorIPAddress(
  data: OperatorIPAddress,
  operatorId?: string
): Promise<OperatorIPProcessResponse> {
  return http
    .post(
      buildUrl(`api/v1/operator/${operatorId || '{operatorId}'}/ip-address`),
      {
        ipAddress: data.ipAddress
      }
    )
    .then(({ data }: AxiosResponse): OperatorIPProcessResponse => data);
}

/**
 * Update operator ip address.
 * @returns updated operator ip address id.
 */
export async function updateOperatorIPAddress(
  data: OperatorIPAddress
): Promise<OperatorIPProcessResponse> {
  return http
    .put(buildUrl(`api/v1/operator/{operatorId}/ip-address/${data.id}`), {
      ipAddress: data.ipAddress
    })
    .then(({ data }: AxiosResponse): OperatorIPProcessResponse => data);
}

/**
 * Get operator mode.
 * @returns operator mode data.
 */
export async function getOperatorMode(): Promise<OperatorMode> {
  return http
    .get(buildUrl(`api/v1/operator/{operatorId}/mode`))
    .then(({ data }: AxiosResponse): OperatorMode => data);
}

/**
 * Update operator mode.
 */
export async function updateOperatorMode(data: OperatorMode): Promise<unknown> {
  return http.post(buildUrl(`api/v1/operator/{operatorId}/mode`), data);
}

/**
 * Get all operators by account.
 */
export async function getAllOperators(): Promise<AccountOperator[]> {
  return http
    .get(`api/v1/operator`)
    .then(({ data }: AxiosResponse): AccountOperator[] => data);
}

/**
 * Get operator widget url.
 */
export async function getOperatorWidgetUrl(
  apiKey: string
): Promise<WidgetUrlResponce> {
  return http
    .get(`api/v2/user/widget`, {
      headers: {
        'X-API-KEY': apiKey
      }
    })
    .then(({ data }: AxiosResponse): WidgetUrlResponce => data);
}

/**
 *  Get widget URL for user (authenticated/anonymous)
 * @param apiKey
 * @param partnerName
 * @return link to widget
 */
export async function getOperatorTestWidget(
  apiKey: string,
  partnerName?: string
): Promise<WidgetDemoURL> {
  return http
    .get(buildUrl('/api/v1/user/{operatorId}/widget'), {
      headers: {
        'X-API-KEY': apiKey
      },
      params: {
        partnerName,
        'admin-settings': true
      }
    })
    .then(({ data }: AxiosResponse): WidgetDemoURL => data);
}

/**
 * Get onboarding progress
 * @param {string|number} operatorId
 */
export const getOnboardingProgress = async (
  operatorId: string | number = '{operatorId}'
): Promise<TOnboardingProgress> =>
  http
    .get(buildUrl(`/api/v1/operator-onboarding/${operatorId}/progress`))
    .then(({ data }: AxiosResponse): TOnboardingProgress => data);

/**
 * Update onboarding progress
 * @param {TOnboardingStepPayload} payload
 * @param {string|number} operatorId
 */
export const updateOnboardingProgress = async (
  payload: Partial<TOnboardingStepPayload>,
  operatorId: string | number = '{operatorId}'
): Promise<TOnboardingProgress> =>
  http
    .patch(
      buildUrl(`/api/v1/operator-onboarding/${operatorId}/progress`),
      payload
    )
    .then(({ data }: AxiosResponse): TOnboardingProgress => data);

export async function saveActiveOperator(operatorId: number): Promise<string> {
  return http
    .post(`/api/v1/operator/${operatorId}/save-active`)
    .then(({ data }: AxiosResponse): string => data);
}

export async function getInvitedUsers(): Promise<IInvitedUsers[]> {
  return http
    .get(`/api/v1/security/admin/invite`, { cacheTime: 0 })
    .then(({ data }: AxiosResponse): IInvitedUsers[] => data);
}

export async function resendInvite(inviteId: number): Promise<string> {
  return http
    .post(`/api/v1/security/admin/invite/${inviteId}`)
    .then(({ data }: AxiosResponse): string => data);
}

export async function deleteInvite(inviteId: number): Promise<string> {
  return http
    .delete(`/api/v1/security/admin/invite/${inviteId}`)
    .then(({ data }: AxiosResponse): string => data);
}
