















































































import { mapGetters, mapState } from 'vuex';
import { startCase } from 'lodash'; // eslint-disable-line
import ColorPicker from '@/components/core/ColorPicker.vue';
import BaseLoader from '@/components/core/BaseLoader.vue';
import { getWidgetTheme, setWidgetTheme } from '@/api/WidgetSettings';
import { getOperatorTestWidget } from '@/api/Onboarding';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import CodeBlockWrapper from '@/components/code-blocks/CodeBlockWrapper.vue';

interface ThemeData {
  colors: string[];
  pickersColors: string[];
  value: string | null;
}

const defaultThemeData = () => ({
  colors: [],
  value: null
});

type Colors = { [key: string]: string };

export default {
  name: 'OnboardingUISettingsContent',
  components: { ColorPicker, BaseLoader, CodeBlockWrapper },
  data(): unknown {
    return {
      themes: [],
      currentTheme: null,
      themeColors: [],
      colorsMap: [
        'main-color',
        'theme-background-color',
        'text-color',
        'theme-block-background-color',
        'text-color-button',
        'theme-block-inner-background-color'
      ],
      init: false,
      loading: false,
      widgetInitTheme: null,
      widgetLinkAnonymous: ''
    };
  },
  computed: {
    ...mapState('TokensInfo', ['tokenPrice']),
    ...mapState('Onboarding', ['selectedOperator', 'apiKey']),
    ...mapState('Auth', ['token']),
    ...mapGetters('app', ['isCopyStakeRole']),
    ...mapGetters('SuperAdmin', ['isCurrentCopyStakeAccount']),
    isCopyStakeAccount(): boolean {
      return this.isCopyStakeRole || this.isCurrentCopyStakeAccount;
    },
    isCustomThemeEnabled(): boolean {
      if (!this.init) return false;

      return !this.currentTheme;
    },
    themeMode: {
      get(): string {
        return this.currentTheme ? 'default' : 'custom';
      },
      set(value: string): void {
        this.currentTheme = value === 'default' ? this.widgetInitTheme : null;
      }
    },
    showControls(): boolean {
      return (
        this.$role.can.update('onboarding') ||
        this.$role.can.update('home colors')
      );
    }
  },
  watch: {
    currentTheme: 'changeTheme'
  },
  mounted(): void {
    window.addEventListener('message', this.handlePostMessage);
    this.getWidgetLink();
  },
  beforeDestroy(): void {
    window.removeEventListener('message', this.handlePostMessage);
  },
  methods: {
    getWidgetLink(): void {
      if (this.isCopyStakeAccount) {
        const baseUrl = process.env.VUE_APP_API_URL;

        const url = new URL(baseUrl.replace('api', 'widget'));

        url.pathname += 'stream/';

        url.searchParams.append('demo', 'true');
        url.searchParams.append('sale', 'true');

        this.widgetLinkAnonymous = url.toString();

        return;
      }

      getOperatorTestWidget(this.apiKey)
        .then(({ url }) => {
          this.widgetLinkAnonymous = url;
        })
        .catch(console.log);
    },
    sendMessage(message: unknown): void {
      this.$refs.frame?.contentWindow.postMessage(message, '*');
    },
    selectTheme(theme: ThemeData): void {
      this.currentTheme = theme;
    },
    changeTheme(): void {
      if (this.currentTheme === this.widgetInitTheme && this.init) {
        const themeColors = this.themes.find(
          ({ value }) => value === this.widgetInitTheme
        )?.pickersColors;

        if (themeColors) {
          this.themeColors = this.getThemeColors(themeColors);
        }
      }
      this.sendMessage({ type: 'theme', value: this.currentTheme });
    },
    changeColors(key: string, value: string): void {
      if (this.themeColors[key].toLowerCase() === value) return;

      this.currentTheme = null;
      this.themeColors[key] = value;
      this.sendMessage({
        type: 'change-colors',
        value: this.themeColors
      });
    },
    formatLabel(name: string): string {
      return name
        .split('-')
        .filter((n) => n !== 'theme')
        .map(startCase)
        .join(' ');
    },
    getThemeColors(colors: string[]): Colors {
      if (!colors) return;

      return {
        'main-color': colors[0],
        'text-color': colors[1],
        'text-color-button': colors[2],
        'theme-background-color': colors[3],
        'theme-block-background-color': colors[4],
        'theme-block-inner-background-color': colors[5]
      };
    },
    handlePostMessage(event: MessageEvent): void {
      const {
        data: { type, value }
      } = event;

      if (type === 'widget-themes') {
        this.themes = value.themes.map((theme) =>
          theme.value === value.selected.value
            ? { ...theme, ...value.selected }
            : theme
        );

        this.$nextTick(async () => {
          this.widgetInitTheme = value.selected.value;
          const data = {
            theme: value.selected.value,
            pickersColors: this.getThemeColors(value.selected.pickersColors)
          };

          if (!this.init) {
            try {
              const theme = await getWidgetTheme();
              data.theme = theme.value;
              if (Array.isArray(theme.colors) && theme.colors.length > 0) {
                data.pickersColors = theme.colors.reduce(
                  (all, { type, color }) => ({ ...all, [type]: color }),
                  {}
                );
              }
            } catch (e) {
              console.log('Operator no theme selected');
            }
          }

          this.currentTheme = data.theme;
          this.themeColors = data.pickersColors;
          this.sendMessage({
            type: 'change-colors',
            value: this.themeColors
          });

          setTimeout(() => {
            this.init = true;
          }, 200);
        });
      }
    },
    saveTheme(clear: boolean = false): void {
      this.loading = true;
      const themeData = defaultThemeData();
      themeData.colors = Object.entries(this.themeColors).map(
        ([type, color]) => ({ type, color })
      );
      themeData.value = this.currentTheme;

      setWidgetTheme(themeData)
        .then(() => {
          this.$toast.success(
            clear
              ? 'Widget color has been set to default.'
              : `Loyalty Page UI has been saved`
          );
          if (!clear) {
            this.$emit('save');
          }
          this.getWidgetLink();
        })
        .catch(errorToastMessage)
        .finally(() => {
          this.loading = false;
          window.dataLayer.push({
            event: 'token_info_section_finished',
            eventCategory: 'onboarding',
            eventAction: 'status_update',
            eventLabel: 'ui_settings_section_finished'
          });
        });
    },
    linkAction(): void {
      window.dataLayer.push({
        event: 'demo_url_click',
        eventCategory: 'onboarding',
        eventAction: 'click',
        eventLabel: 'demo_url'
      });
    }
  }
};
