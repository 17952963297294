


































import CodeBlock from '@/components/code-blocks/CodeBlock.vue';
import { mapGetters, mapState } from 'vuex';
import { ELoyaltyFeatureFlag } from '@/store/modules/feature-flags/FeatureFlagNames';

const OnboardingCopystakeSettings = (): any =>
  import(
    '@/views/OnboardingView/onboardingStepsComponents/OnboardingCopystakeSettings.vue'
  );
export default {
  name: 'OnboardingAccountCreationContent',
  components: {
    CodeBlock,
    OnboardingCopystakeSettings
  },
  computed: {
    ...mapState('Onboarding', [
      'apiKey',
      'integrationUrl',
      'secret',
      'loading'
    ]),
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('app', ['isCopyStakeRole']),
    ...mapGetters('SuperAdmin', ['isCurrentCopyStakeAccount']),
    ...mapGetters('FeatureFlags', ['isEnabledFeatureFlag']),
    isCopyStakeAccount(): boolean {
      return this.isCopyStakeRole || this.isCurrentCopyStakeAccount;
    },
    operatorApiKey(): string {
      return (
        (!this.loading.apiKeys && this.apiKey) ||
        'Complete a questionary above to get the API key'
      );
    },
    showCopyStakeSettings(): boolean {
      if (this.isSuperAdmin) return true;

      return this.isEnabledFeatureFlag(ELoyaltyFeatureFlag.COPY_STAKE);
    }
  },
  methods: {
    apiKeyCopiAction(): void {
      window.dataLayer.push({
        event: 'copy_api_key_click',
        eventCategory: 'onboarding',
        eventAction: 'click',
        eventLabel: 'copy_api_key'
      });
    },
    apiURLCopiAction(): void {
      window.dataLayer.push({
        event: 'copy_test_integration_url_click',
        eventCategory: 'onboarding',
        eventAction: 'click',
        eventLabel: 'copy_test_integration_url'
      });
    },
    copySecretKeyAction(): void {
      window.dataLayer.push({
        event: 'secret_key_copy_click',
        eventCategory: 'onboarding',
        eventAction: 'click',
        eventLabel: 'secret_key_copy_click'
      });
    }
  }
};
