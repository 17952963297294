













import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import ColorsIcon from '@/assets/images/icons/colors-icon.svg?inline';
import OnboardingUISettingsContent from '@/views/OnboardingView/onboardingStepsComponents/onboardingContents/OnboardingUISettingsContent.vue';
import { EOnbordingStep } from '@/api/schema';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';
import OnboardingUISettingsCopyStakeContent from '@/views/OnboardingView/onboardingStepsComponents/onboardingCopyStakeContents/OnboardingUISettingsCopyStakeContent.vue';
import { mapState } from 'vuex';

export default {
  name: 'OnboardingUISettings',
  mixins: [OnboardingMixin],
  components: {
    OnboardingUISettingsCopyStakeContent,
    OnboardingUISettingsContent,
    ExpansionProgressPanel,
    ColorsIcon
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.uiSettingsProgress
  }),
  watch: {
    isExpanded(status: number | undefined): void {
      if (status === 0) {
        window.dataLayer.push({
          event: 'ui_settings_section_opened',
          eventCategory: 'onboarding',
          eventAction: 'opened',
          eventLabel: 'ui_settings_section_opened'
        });
      }
    }
  },
  computed: {
    ...mapState('app', ['isSuperAdmin']),
    getComponent(): string {
      return this.isCopyStakeAccount
        ? 'OnboardingUISettingsCopyStakeContent'
        : 'OnboardingUISettingsContent';
    }
  },
  methods: {
    updateProgress(): void {
      this.$emit('updateProgress', this.onboardingStepName);
    }
  }
};
