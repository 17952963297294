import * as vr from '@/helpers/validation';

const clearTickerName = (v: string): string =>
  `${v || ''}`.replace(/_([sdt])\d?$/gi, '').trim();

export default {
  data: (): unknown => ({
    rules: Object.freeze({
      tokenNameRules: [(v) => vr.required(v, 'Token name')],
      tokenTickerRules: [
        (v) => !v?.includes(' ') || 'No blank space available',
        (v) => vr.required(clearTickerName(v), 'Token symbol'),
        (v) => vr.maxLength(clearTickerName(v), 5),
        (v) => vr.minLength(clearTickerName(v), 3),
        vr.validByRegExp
      ],
      ggrRules: [(v) => vr.required(v, 'Monthly GGR')],
      tokenNumberRules: [
        (v) => vr.required(v, 'Amount of tokens to emit'),
        vr.validNumber,
        vr.positiveNumber
      ],
      tokenPriceRules: [
        (v) => vr.required(v, 'Price'),
        vr.validNumber,
        vr.positiveNumber
      ],
      activeUsers: [
        (v) => {
          return vr.required(v, 'Amount of active users');
        }
      ],
      websiteUrlWithoutProtocol: [
        (v) => vr.required(v, 'Website URL'),
        vr.urlWithoutProtocol
      ],
      websiteUrl: [(v) => vr.required(v, 'Website URL'), vr.url]
    })
  }),
  methods: {
    clearTickerName
  }
};
