

















































import { mapGetters, mapState } from 'vuex';
import { tokensToCurrency } from '@/helpers/currencyHelper';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import type { UserBalancesData } from '@/api/schema';
import { EProfitActivity } from '@/api/schema';
import type { PropType } from 'vue';

interface DataMap {
  name: string;
  value: string;
  subValue?: string;
  visible?: boolean;
  color: string;
}

export default {
  name: 'UserManagementBalance',
  components: { Tooltip },
  props: {
    loading: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    data: {
      type: Object as PropType<UserBalancesData>,
      default: {} as UserBalancesData
    },
    showTokenPurchase: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      EProfitActivity
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['isMobile']),
    ...mapGetters('ClientsInfo', ['isListed']),
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('TokensInfo', ['tokenPrice']),

    dataMap(): DataMap[] {
      return [
        {
          name: 'Wallet',
          value: this.getTokensValue(this.data['walletBalance']),
          subValue: this.getCurrencyValue(this.data['walletBalance']),
          color: '#56CA00'
        },
        {
          name: 'Locked in Hold to Earn programs',
          value: this.getTokensValue(this.data['stakedBalance']),
          subValue: this.getCurrencyValue(this.data['stakedBalance']),
          visible: !this.isMobile,
          color: '#9155FD'
        },
        {
          name: 'Staking Limit Coefficient',
          value: this.data['limitedProgramCoefficient'],
          visible: !this.isMobile,
          color: '#FFB400',
          info: 'Staking limit coefficient defines max count of tokens, which should be added to limited hold to earn programs by this user. Max staking amount = Staking Limit Coefficient * Play to Earn'
        },
        {
          name: 'Token Purchase volume',
          value: this.getTokensValue(this.data['preSaleVolume']),
          visible: this.isShowTokenPurchaseVolume,
          color: '#32BAFF',
          info: 'Token Purchase Volume = Token Purchase coefficient * play to earn + Withdrawals - Deposited funds'
        }
      ].filter((item: DataMap): boolean => {
        return item.visible ?? true;
      });
    },
    isShowTokenPurchaseVolume(): boolean {
      return (
        !this.isListed &&
        this.data['preSaleVolume'] !== null &&
        this.showTokenPurchase
      );
    }
  },
  methods: {
    getTokensValue(amount: number): string {
      return `${this.$options.filters.numeralSpaces(amount, '0,0.[00]')} ${
        this.tokenSymbol
      }`;
    },
    getCurrencyValue(amount: number): string {
      return `${this.$options.filters.numeralSpaces(
        tokensToCurrency(amount),
        '0,0.[00]'
      )} ${this.tokenPrice.rateCurrencyTicker}`;
    }
  }
};
