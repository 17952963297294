










import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import TokenInfoIcon from '@/assets/images/icons/token-info-icon.svg?inline';
import OnboardingTokenInfoContent from '@/views/OnboardingView/onboardingStepsComponents/onboardingContents/OnboardingTokenInfoContent.vue';
import { EOnbordingStep } from '@/api/schema';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';

export default {
  name: 'OnboardingTokenInfo',
  mixins: [OnboardingMixin],
  components: {
    ExpansionProgressPanel,
    TokenInfoIcon,
    OnboardingTokenInfoContent
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.tokenInfoProgress
  }),
  watch: {
    isExpanded(status: number | undefined): void {
      if (status === 0) {
        window.dataLayer.push({
          event: 'token_info_section_opened',
          eventCategory: 'onboarding',
          eventAction: 'opened',
          eventLabel: 'invite_developer_section_opened'
        });
      }
    }
  },
  methods: {
    doneStep(): void {
      this.$emit('switchExpanded', this.onboardingStepName, false);
      this.$emit('updateProgress', this.onboardingStepName);
    }
  }
};
