export const capitalization = (s: string): string => {
  return s
    ?.split(' ')
    .map((word: string): string => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

export const textOverflowMiddle = (
  s: string,
  fromStart: number = 2,
  fromEnd: number = 2
): string | null => {
  if (!s) return null;
  const str = `${s}`;
  if (str.length < fromStart + fromEnd + 3) return str;
  return (
    str.substring(0, fromStart) +
    '...' +
    str.substring(str.length - fromEnd, str.length)
  );
};

export function truncateString(str: string, maxLength: number): string {
  try {
    return str.length > maxLength ? str.slice(0, maxLength - 3) + '...' : str;
  } catch (e) {
    return '';
  }
}

export const toTitleCase = (text: string): string => {
  const lowers = [
    'A',
    'An',
    'The',
    'And',
    'But',
    'Or',
    'For',
    'Nor',
    'As',
    'At',
    'By',
    'For',
    'From',
    'In',
    'Into',
    'Near',
    'Of',
    'On',
    'Onto',
    'To',
    'With'
  ].map((word) => word.toLowerCase());
  const uppers = ['Id', 'Tv'].map((word) => word.toLowerCase());

  return text
    .replaceAll('_', ' ')
    .split(' ')
    .map((word, idx, array) => {
      const lowerWord = word.toLowerCase();
      if (lowers.includes(lowerWord) && idx !== 0 && idx !== array.length - 1) {
        return lowerWord;
      } else if (uppers.includes(lowerWord)) {
        return word.toUpperCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    })
    .join(' ');
};

export const boldHighlight = (fullText: string, searchText: string): string => {
  return fullText.replace(new RegExp(searchText, 'gi'), (match) => {
    return '<strong>' + match + '</strong>';
  });
};
