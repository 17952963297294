import { http } from '@/api/Connect';
import { BASE, BASE_OPERATOR } from '@/api/CopyStake/BaseUrls';
import { AxiosResponse } from 'axios';

import {
  ICopyStakeFeatureFlagItem,
  ICopyStakeFeatureFlagsListResponse,
  IGeneralFeatureFlagsListResponse,
  IManageAnonymousRegularUserFeatureFlagPayload,
  IManageUserIdsFeatureFlagPayload
} from '@/api/schema';

const API_GENERAL_FF = (): string => `${BASE()}/feature-flags`;
const API_OPERATOR_FF = (): string => `${BASE_OPERATOR()}/feature-flags`;

export async function getGeneralFeatureFlagItems(): Promise<IGeneralFeatureFlagsListResponse> {
  return http
    .get(`${API_GENERAL_FF()}`, { cacheTime: 0 })
    .then(({ data }: AxiosResponse<IGeneralFeatureFlagsListResponse>) => data);
}

export async function updateGeneralFeatureFlagItem(
  featureId: number,
  status: boolean
): Promise<string> {
  return http
    .patch(`${API_GENERAL_FF()}/${featureId}`, null, {
      params: {
        enable: status
      }
    })
    .then(({ data }: AxiosResponse): string => data);
}

export async function getCopyStakeFeatureFlagItems(): Promise<
  ICopyStakeFeatureFlagItem[]
> {
  return http
    .get(`${API_OPERATOR_FF()}`, { cacheTime: 0 })
    .then(
      ({
        data
      }: AxiosResponse<ICopyStakeFeatureFlagsListResponse>): ICopyStakeFeatureFlagItem[] =>
        data.operatorFeatureFlags
    );
}

export async function updateStateFeatureFlag(
  featureId: number,
  enable: boolean
): Promise<unknown> {
  return http.put(`${API_OPERATOR_FF()}/${featureId}/enable`, null, {
    params: { enable }
  });
}

export async function updateVisibleStateFeatureFlag(
  featureId: number,
  visible: boolean
): Promise<unknown> {
  return http.put(`${API_OPERATOR_FF()}/${featureId}/admin-visible`, null, {
    params: { 'admin-visible': visible }
  });
}

export async function addUserIdsFeatureFlag(
  featureId: number,
  payload: IManageUserIdsFeatureFlagPayload
): Promise<unknown> {
  return http.post(`${API_OPERATOR_FF()}/${featureId}`, payload);
}

export async function deleteUserIdsFeatureFlag(
  featureId: number,
  payload: IManageUserIdsFeatureFlagPayload
): Promise<unknown> {
  return http.delete(`${API_OPERATOR_FF()}/${featureId}`, {
    data: payload
  });
}

export async function updateAnonymousRegularUserStateFeatureFlag(
  featureId: number,
  payload: IManageAnonymousRegularUserFeatureFlagPayload
): Promise<unknown> {
  return http.put(
    `${API_OPERATOR_FF()}/${featureId}/anonymous-regular-user`,
    payload
  );
}
