










import ExpansionProgressPanel from '@/components/ExpansionProgressPanel.vue';
import TokenInfoIcon from '@/assets/images/icons/token-info-icon.svg?inline';
import OnboardingIntegrationSettingsContent from '@/views/OnboardingView/onboardingStepsComponents/onboardingContents/OnboardingIntegrationSettingsContent.vue';
import CogIcon from '@/assets/images/icons/cog-icon.svg?inline';
import { EOnbordingStep } from '@/api/schema';
import OnboardingMixin from '@/mixins/Onboarding/OnboardingMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'OnboardingIntegrationSettings',
  mixins: [OnboardingMixin],
  components: {
    ExpansionProgressPanel,
    OnboardingIntegrationSettingsContent,
    TokenInfoIcon,
    CogIcon
  },
  data: (): unknown => ({
    onboardingStepName: EOnbordingStep.integrationSettingsProgress
  }),
  computed: {
    ...mapGetters('Onboarding', ['isTestEnv']),
    getTime(): string {
      return this.isTestEnv ? '5 Minutes' : '';
    }
  },
  methods: {
    updateProgress(): void {
      this.$emit('updateProgress', this.onboardingStepName);
    }
  }
};
